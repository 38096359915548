<template>
  <div class="game-header">
    <!-- left -->
    <div class="geme-header__left">
      <div class="game-header__player">
        <div
          class="game-header__player-avatar"
          :style="{
            marginLeft: '10px',
            backgroundImage: `url('/images/game/user.png')`,
          }"
        />
        <div
          v-if="$store.state.ui.width > 768"
          class="game-header__player-name flex flex-row align-center content-center"
          :style="{
            height: '32px',
          }"
        >
          {{ user.username }}
        </div>
      </div>
    </div>
    <!-- levels -->
    <div
      v-if="gameInfo.current_level !== 'final'"
      v-show="gameInfo.current_level !== undefined"
      class="game-header__center"
      :style="{
        paddingTop: ['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level)
          ? '24px'
          : '0px',
        pointerEvents: 'none',
        userSelect: 'none',
      }"
    >
      <div
        v-if="['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level)"
        class="flex flex-row flex-wrap w-full justify-center items-center"
        :style="{
          paddingTop: '0px',
          borderRadius: '8px',
          background: '#000',
          marginTop: $store.state.ui.width > 768 ? '-25px' : '0px',
          maxWidth: $store.state.ui.width > 768 ? '' : '170px',
          fontSize: $store.state.ui.width > 768 ? '' : '10px',
          paddingBottom: $store.state.ui.width > 768 ? '0px' : '12px',
        }"
      >
        <div
          class="name"
          :class="[
            $store.state.ui.width > 768 ? '' : 'w-full justify-center flex',
          ]"
        >
          {{ levelMap.name }}
        </div>
        <div v-for="h in allQuestions" :key="h.id" style="font-size: 40px">
          <div v-if="h.type === 'correct'" class="plus" />
          <div v-if="h.type === 'incorrect'" class="minus" />
        </div>
        <div v-for="s in 5 - allQuestions.length" :key="s.id">
          <div class="empty" />
        </div>
      </div>
      <div v-else class="game-header__stage-container">
        <div
          v-for="(l, lkey, li) in levels"
          :key="lkey"
          class="game-header__stage-item"
          :class="{ current: lkey === gameInfo.current_level }"
        >
          {{ $store.state.ui.width > 768 ? l.name : li + 1 }}
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="geme-header__right">
      <div class="game-header__control-container">
        <button
          class="game-header__control-item"
          :style="{
            backgroundImage: soundToggleBackgroundImage,
          }"
          @click="soundToggle"
        />
        <button
          class="game-header__control-item"
          style="background-image: url('/images/icons/fi_menu_32px.svg')"
          @click="menuToggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsModal from '@/components/SettingsModal';

export default {
  name: 'GameHeader',
  data() {
    return {
      menuOpened: false,
      allQuestions: [],
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game?.gameInfoMap?.levels[
        this.gameInfo.current_level
      ];
    },
    user() {
      return this.$store.getters.user.data;
    },
    levels() {
      const levels = {};
      levels.q_1 = this.$store.state.game.gameInfoMap.levels.c_1;
      levels.q_2 = this.$store.state.game.gameInfoMap.levels.c_2;
      levels.q_3 = this.$store.state.game.gameInfoMap.levels.c_3;
      return levels;
    },
    soundToggleBackgroundImage() {
      if (this.$store.state.ui.soundOn)
        return "url('/images/icons/volume_on_32px.svg')";
      else return "url('/images/icons/volume_off_32px.svg')";
    },
    //
    currentLevel() {
      return this.gameInfo?.current_level;
    },
    currentLevelResult() {
      return this.gameInfo?.result?.level[this.currentLevel];
    },
    corrects() {
      return this.currentLevelResult?.correct || 0;
    },
    incorrects() {
      return this.currentLevelResult?.incorrect || 0;
    },
  },
  watch: {
    corrects: {
      handler(to) {
        if (to > 0) {
          this.allQuestions.push({ type: 'correct' });
        }
      },
    },
    incorrects: {
      handler(to) {
        if (to > 0) {
          this.allQuestions.push({ type: 'incorrect' });
        }
      },
    },
    'gameInfo.current_level': {
      handler() {
        this.allQuestions = [];
      },
    },
    '$store.state.ui.soundOn': {
      immediate: true,
      handler(to) {
        if (!this.sound)
          this.sound = this.$sound(
            '/images/game/audio/theme_1.mp3',
            false,
            0.2
          );
        if (to) {
          if (this.sound) this.sound.play();
        } else {
          if (this.sound) this.sound.pause();
        }
      },
    },
  },
  methods: {
    soundToggle() {
      this.$log('soundToggle');
      this.$store.commit('ui/SET_STATE_PROP', [
        'soundOn',
        !this.$store.state.ui.soundOn,
      ]);
    },
    menuToggle() {
      this.$log('menuToggle');
      if (this.menuOpened) {
        this.$modal.hide('settings-modal');
      } else {
        this.$modal.show(
          SettingsModal,
          {},
          {
            name: 'settings-modal',
            classes: 'settings-modal',
            height: 'auto',
            width: this.$store.state.ui.width > 768 ? '700px' : '100%',
            clickToClose: true,
            scrollable: true,
          },
          {
            'before-open': () => {},
            'before-close': () => {},
          }
        );
      }
      this.menuOpened = !this.menuOpened;
    },
  },
  beforeDestroy() {},
  async mounted() {
    for (let n = 0; n < this.corrects; n++) {
      this.allQuestions.push({ type: 'correct' });
    }
    for (let n = 0; n < this.incorrects; n++) {
      this.allQuestions.push({ type: 'incorrect' });
    }
  },
};
</script>

<style lang="scss" scoped>
.name {
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
  margin-right: 10px;
}
.empty {
  background: url('/images/icons/empty.svg');
  width: 20px;
  height: 20px;
  @apply bg-center bg-contain bg-no-repeat;
  margin: 0 5px 0px 0px;
}
.plus {
  background: url('/images/icons/plus.svg');
  width: 20px;
  height: 20px;
  margin: 0 5px 0px 0px;
  @apply bg-center bg-contain bg-no-repeat;
}
.minus {
  background: url('/images/icons/minus.svg');
  width: 20px;
  height: 20px;
  margin: 0 5px 0px 0px;
  @apply bg-center bg-contain bg-no-repeat;
}
.heart {
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 0;
  background: url('/images/game/heart.svg');
  background-size: 100%;
}
.game-header {
  max-width: 1280px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px 0 0px;
  box-sizing: border-box;
  position: absolute;
  z-index: 99;
  top: 0;
  &__player {
    &-avatar {
      background-color: #131120;
      --size: 32px;
      width: var(--size);
      height: var(--size);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      --clip-size: 7px;
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--clip-size)),
        calc(100% - var(--clip-size)) 100%,
        0 100%
      );
    }
    &-name {
      display: inline-block;
      font-family: 'BlenderPro Bold';
      padding-top: 7px;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 1px 1px 0px #000000;
      vertical-align: middle;
    }
  }
  &__stage {
    &-container {
      margin: 0 -2px;
      display: flex;
      flex-wrap: wrap;
    }
    &-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 40px;
      background: #131120;
      box-sizing: border-box;
      margin: 5px 2px;
      margin-top: 20px;
      padding: 0 8px;
      font-size: 18px;
      line-height: 100%;
      color: #7f7e8a;
      border-radius: 8px;
      font-family: 'BlenderPro';
      line-height: 30px;
      text-align: center;
      // --clip-size: 7px;
      --border-color: #2e2c3f;
      border: 1.5px solid var(--border-color);
      position: relative;
      &:before {
        position: absolute;
        content: '';
        border-bottom: var(--clip-size) solid var(--border-color);
        border-left: var(--clip-size) solid transparent;
        bottom: 0;
        right: 0;
        opacity: 0.9;
      }
      &.current {
        width: auto;
        padding: 0 13px 0 14px;
        --border-color: #7ed665;
        color: #7ed665;
      }
    }
  }
  &__control {
    &-container {
      margin: 0 0px;
    }
    &-item {
      display: inline-block;
      border: none;
      margin: 0 3px;
      --size: 32px;
      width: var(--size);
      height: var(--size);
      background: #000;
      border: 1px solid #262826;
      border-radius: 5px;
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        background-color: #484b48;
      }
    }
  }
}

@media (max-width: 768px) {
  .game-header {
    &__player {
      &-avatar {
        --size: 26px;
      }
      &-name {
        font-size: 13px;
      }
    }
    &__stage {
      &-container {
        display: none;
      }
    }
    &__control {
      &-item {
        --size: 26px;
        background-size: 12px;
      }
    }
  }
}
</style>
