var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game-header"},[_c('div',{staticClass:"geme-header__left"},[_c('div',{staticClass:"game-header__player"},[_c('div',{staticClass:"game-header__player-avatar",style:({
          marginLeft: '10px',
          backgroundImage: "url('/images/game/user.png')",
        })}),(_vm.$store.state.ui.width > 768)?_c('div',{staticClass:"game-header__player-name flex flex-row align-center content-center",style:({
          height: '32px',
        })},[_vm._v(" "+_vm._s(_vm.user.username)+" ")]):_vm._e()])]),(_vm.gameInfo.current_level !== 'final')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameInfo.current_level !== undefined),expression:"gameInfo.current_level !== undefined"}],staticClass:"game-header__center",style:({
      paddingTop: ['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level)
        ? '24px'
        : '0px',
      pointerEvents: 'none',
      userSelect: 'none',
    })},[(['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level))?_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center items-center",style:({
        paddingTop: '0px',
        borderRadius: '8px',
        background: '#000',
        marginTop: _vm.$store.state.ui.width > 768 ? '-25px' : '0px',
        maxWidth: _vm.$store.state.ui.width > 768 ? '' : '170px',
        fontSize: _vm.$store.state.ui.width > 768 ? '' : '10px',
        paddingBottom: _vm.$store.state.ui.width > 768 ? '0px' : '12px',
      })},[_c('div',{staticClass:"name",class:[
          _vm.$store.state.ui.width > 768 ? '' : 'w-full justify-center flex' ]},[_vm._v(" "+_vm._s(_vm.levelMap.name)+" ")]),_vm._l((_vm.allQuestions),function(h){return _c('div',{key:h.id,staticStyle:{"font-size":"40px"}},[(h.type === 'correct')?_c('div',{staticClass:"plus"}):_vm._e(),(h.type === 'incorrect')?_c('div',{staticClass:"minus"}):_vm._e()])}),_vm._l((5 - _vm.allQuestions.length),function(s){return _c('div',{key:s.id},[_c('div',{staticClass:"empty"})])})],2):_c('div',{staticClass:"game-header__stage-container"},_vm._l((_vm.levels),function(l,lkey,li){return _c('div',{key:lkey,staticClass:"game-header__stage-item",class:{ current: lkey === _vm.gameInfo.current_level }},[_vm._v(" "+_vm._s(_vm.$store.state.ui.width > 768 ? l.name : li + 1)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"geme-header__right"},[_c('div',{staticClass:"game-header__control-container"},[_c('button',{staticClass:"game-header__control-item",style:({
          backgroundImage: _vm.soundToggleBackgroundImage,
        }),on:{"click":_vm.soundToggle}}),_c('button',{staticClass:"game-header__control-item",staticStyle:{"background-image":"url('/images/icons/fi_menu_32px.svg')"},on:{"click":_vm.menuToggle}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }