<template>
  <div
    :style="{
      maxWidth: 700+'px',
    }"
  >
    <button
      class="settings-modal__close-button"
      @click="$modal.hide(modalName)"
    >
      <icon-close />
    </button>
    <div class="settings-modal__block settings-modal__title">
      Настройки
    </div>
    <div class="settings-modal__block settings-modal__content">
      <!-- <profile-form /> -->
      <a href="https://geecko.notion.site/ae153ef91b9741848b6cbf34d83895a8" class="geecko-link" style="margin-top: 0px" target="_blank">
        Политика конфиденциальности
      </a>
      <a href="https://geecko.notion.site/24dd3b1d9c0b43269118b959f04c2c44" class="geecko-link" target="_blank">
        Пользовательское соглашение
      </a>
    </div>
    <div class="settings-modal__block settings-modal__bottom">
      <!-- go top -->
      <!-- <button
        class="geecko-link exit"
        @click="goTop()"
      >
        Турнирная таблица
      </button> -->
      <!-- logout -->
      <button
        class="geecko-link exit"
        @click="handleLogout()"
      >
        Выйти из игры
      </button>
      <!-- restart -->
      <button
        v-if="isLocal"
        class="geecko-link"
        @click="handleRestart()"
      >
        Рестарт
      </button>
      <!-- finish -->
      <!-- <button
        v-if="isDev || isLocal"
        class="geecko-link"
        @click="handleFinish()"
      >
        Закончить игру
      </button> -->
    </div>
  </div>
</template>

<script>
// import ProfileForm from '@/forms/ProfileForm.vue';
import IconClose from '@/components/Icons/IconClose';

export default {
  name: 'SettingsModal',
  components: {
    // ProfileForm,
    IconClose,
  },
  data() {
    return {
      modalName: 'settings-modal',
    };
  },
  computed: {
    isDev() {
      return this.$store.getters.isDev
    },
    isLocal() {
      return this.$store.getters.isLocal
    },
  },
  methods: {
    async goTop () {
      this.$log('goTop')
      await this.$modal.hide(this.modalName);
      this.$router.push('/top').catch(() => {})
    },
    async handleLogout() {
      this.$log('handleLogout')
      await this.$modal.hide(this.modalName);
      await this.$store.dispatch('user/logout');
      // await this.$router.push('/');
    },
    async handleRestart() {
      this.$log('handleRestart')
      await this.$modal.hide(this.modalName);
      await this.$store.dispatch('game/restartGame')
      // this.$router.replace({name: 'idle'}).catch(() => {})
    },
    async handleFinish () {
      this.$log('handleFinish')
      await this.$modal.hide(this.modalName)
      await this.$store.dispatch('game/finishGame')
    }
  },
  mounted() {
    this.$log('mounted')
  },
  beforeDestroy () {
    this.$log('beforeDestroy')
  }
};
</script>

<style lang="scss">
.settings-modal {
  background: #221f20;
  position: relative;
  &__close-button {
    position: absolute;
    right: 16px;
    top: 13px;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    color: #7f7e8a;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  &__block {
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #3d3c3d;
    &:last-child {
      border-bottom: none;
    }
  }
  &__title {
    padding-top: 9px;
    padding-bottom: 9px;
    font-family: 'BlenderPro Thin';
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
  }
  &__content {
    padding-top: 26px;
    padding-bottom: 17px;
    padding-right: 198px;
  }
  &__bottom {
    padding-top: 24px;
    padding-bottom: 31px;
  }
}
</style>
