<template>
  <div class="dialog-container">
    <div ref="dialog" class="dialog" :style="{ top: dialogTopValue }">
      <!-- header -->
      <div class="dialog-head">
        Оставь контакты
        <div class="close" @click="$emit('close')" />
      </div>
      <!-- body -->
      <div ref="cont" class="dialog-content">
        <h2 style="color: #fff">
          Чтобы Сбер мог связаться с тобой, оставь свой телефон
        </h2>
        <!-- footer -->
        <input
          id="phone"
          v-model="phone"
          v-mask="'+# (###) ###-##-##'"
          class="input"
          type="phone"
        />
        <p v-if="error" class="error">Введите валидный номер телефона</p>
        <div class="checkbox-container">
          <input v-model="accept" class="checkbox" type="checkbox" checked />
          <p>
            Согласен(-а) на передачу телефонного номера партнёру мероприятия,
            ПАО Сбербанк, для
            <span class="info"
              >взаимодействия со мной по вопросам карьерных возможностей.</span
            >
          </p>
        </div>
        <div
          ref="content"
          class="flex flex-col w-full h-full buttons items-start justify-start"
        >
          <button
            class="geecko-button music-on"
            type="submit"
            @click="handleSubmit('music-on')"
            :disabled="active"
            :style="{
              background: active ? '#7F7E8A !important' : '#FFEF40 !important',
            }"
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    headerImage: { type: String, default: '/images/game/sber-logo.png' },
  },
  data() {
    return {
      dialogTop: 0,
      phone: '7',
      phoneSubmiting: false,
      accept: false,
      error: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    dialogTopValue() {
      return window.innerWidth > 768 && !!this.dialogTop
        ? `calc(50% - ${this.dialogTop}px / 2)`
        : 'auto';
    },
    active() {
      return !this.accept || this.phone.length < 18 || this.phoneSubmiting;
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.dialogTop = this.$refs.dialog.offsetHeight;
      const dia = this.$refs.dialog;
      const anim = new this.$timeline();
      const cont = this.$refs.cont;
      const content = this.$refs.content;
      anim
        .set(dia, { width: '0%', height: '48px' })
        .set(cont, { height: '0%', padding: '0 32px', opacity: 0 })
        .set(content, { opacity: '0' });
      // anim.set(cont, { opacity: '0' });
      anim.to(dia, 0.6, { width: '100%' });
      // anim.to(cont, 1, { opacity: '1' });
      await this.$wait(200);
      anim
        .to(dia, 0.2, { height: 'auto' })
        .to(cont, 0.2, { height: '100%', padding: '24px 32px', opacity: 1 });
      await this.$wait(1000);
      anim.to(content, 0.2, { opacity: '1' });
    });
    console.log(this.user.data);
  },
  methods: {
    async handleSubmit() {
      this.$log('handleSubmit', this.phone);
      this.error = false;
      if (this.phone.length < 11) return;
      try {
        await this.$http.post('/api/user/set-phone', { phone: this.phone });
        await this.$http.patch(`/api/users/${this.user.data.id}`, {
          want_offer: 'positive',
        });
        this.phoneSubmiting = true;
        this.phone = '';
        this.$emit('finished');
        this.phoneSubmiting = false;
      } catch (e) {
        this.error = true;
        console.log('phone set error', e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  width: 20px;
  height: 20px;
  background: url('/images/close.svg');
  cursor: pointer;
  @apply bg-center bg-no-repeat bg-contain;
}
.error {
  margin-top: 5px;
  color: #e24242;
}
.checkbox {
  &-container {
    padding: 20px 0px;
    padding-left: -10px;
    @apply relative flex flex-row w-full justify-start items-start;
    // border: 1px solid red;
    input {
      margin: 0 !important;
      padding: 0;
      color: #fff !important;
      // position: absolute;
      left: 0px;
      max-width: 20px;
      max-height: 20px;
      width: 20px;
      height: 20px;
      border: 1px solid lime;
    }
    p {
      padding-left: 10px;
      margin-top: -5px;
      // border: 1px solid red;
      color: #fff;
      .info {
        background: #3d434b;
        cursor: pointer;
        position: relative;

        &:hover {
          opacity: 1;
          &:after {
            content: 'ООО   «Смарт   Манкиз»   передаст   Ваш   телефонный   номер   генеральномупартнеру   мероприятия   ПАО   «Сбербанк   России»   (Российская   Федерация,117997, Москва, ул. Вавилова, д.19), чтобы Банк мог связаться с Вами повопросам карьерных возможностей. Персональные данные будут использоваться банком для указанной цели втечение 6 месяцев. Вы можете в любой момент отозвать согласие, после чего Банк удалит данные и не будет Вас беспокоить. Чтобы отозвать согласие,напишите на адрес электронной почты HR_feedback@sberbank.ru.';
            position: absolute;
            display: block;
            transition: transform 500ms;
            transform: translateX(-50px);
            // opacity: 0;
            // border: 1px solid red;
            left: 100px;
            right: -30px;
            background: #3d434b;
            bottom: 30px;
            border-radius: 10px;
            padding: 10px;
            line-height: 14px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.buttons {
  // align-items: center;
  // justify-content: space-between;
  margin-top: 10px;
  max-width: 400px;
}
.dialog-container {
  width: 100vw;
  height: 100vh;
  @apply flex items-start justify-center;
  padding-top: 10vh;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.7);
  // border: 1px solid red;
}
.dialog {
  max-width: 750px;
  // height: auto;
  &-head {
    color: #e0e0e0 !important;
    background: #111316;
    border-radius: 8px 8px 0px 0px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    @apply flex items-center justify-between;
    --height: 48px;
    height: var(--height);
    font-family: 'BlenderPro';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 17px */

    letter-spacing: 0.04em;
    text-transform: uppercase;
    // --clip-size: 16px;
    // clip-path: polygon(
    //   0 0,
    //   0 -29px,
    //   calc(100% - var(--clip-size)) 0,
    //   100% var(--clip-size),
    //   100% 100%,
    //   0 100%
    // );
    &__img {
      position: absolute;
      left: 23px;
      bottom: 0;
      --size: 130px;
      height: 48px;
      width: var(--size);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &__name {
      font-size: 17px;
      line-height: 100%;
      font-family: 'BlenderPro Bold';
      line-height: var(--height);
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  &-content {
    background: #191b20;
    border-radius: 0px 0px 8px 8px;
    padding: 24px 32px 32px 32px;
    height: auto;
    // border: 1px solid red;
    @apply w-full flex flex-col;
    h2 {
      font-size: 24px;
    }
    input {
      // max-width: 330px;
      width: 100%;
      background: #3d434b;
      border-radius: 8px;
      padding: 10px;
      margin-top: 10px;
      color: #fff;
      &:focus {
        outline: none;
        // border: none;
      }
    }
    button {
      margin-top: 20px;
    }
  }
}
.dialog-text {
  color: #e0e0e0;
  display: inline-block;
}

@media (max-width: 768px) {
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    button {
      margin-top: 20px;
      max-width: 400px;
      width: 100%;
    }
  }
  .dialog {
    height: 100% !important;
    max-width: 100%;
    &-content {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
